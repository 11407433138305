<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="来源名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'from_name',
          {
            rules: [{ required: true, message: '邮件来源名称不能为空' }],
            initialValue: emailInfo.from_name
          },
        ]" placeholder="显示的邮件来源名称" />
      </a-form-item>
      <a-form-item label="来源邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'from_email',
          {
            rules: [{ required: true, message: '邮件来源邮箱不能为空' }],
            initialValue: emailInfo.from_email
          },
        ]" placeholder="显示的邮件来源邮箱" />
      </a-form-item>
      <a-form-item label="抄送" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'copyto',
          {
            initialValue: emailInfo.copyto
          },
        ]" placeholder="抄送邮件到邮箱" />
      </a-form-item>
      <a-form-item label="密送" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'bind_copy_to',
          {
            initialValue: emailInfo.bind_copy_to
          },
        ]" placeholder="密送邮件到邮箱" />
      </a-form-item>
      <a-form-item label="邮件附件" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-upload :file-list="fileList" :multiple="false" @change="changeFile" :remove="removeFile"
          :customRequest="customRequest">
          <a-button v-if="fileList.length < 4">
            <a-icon type="upload" /> Click to upload
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="邮件是否为纯文本" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'plantext',
          {
            valuePropName: 'checked',
            initialValue: plaintxtStatus,
          },
        ]" @change="handlePlantextChange">
        </a-checkbox>
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: templateStatus,
          },
        ]">
          是否启用
        </a-checkbox>
      </a-form-item>
      <a-divider>主体</a-divider>
      <a-row :gutter="24">
        <a-col :sm="24" :lg="8">
          <a-form-item label="名称" :labelCol="customLabelCol" :wrapperCol="customWrapperCol">
            <a-input v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '名称不能为空' }],
                initialValue: emailInfo.name
              },
            ]" placeholder="名称" />
          </a-form-item>
        </a-col>
        <a-col :sm="24" :lg="8">
          <a-form-item label="主题" :labelCol="customLabelCol" :wrapperCol="customWrapperCol">
            <a-input v-decorator="[
              'subject',
              {
                rules: [{ required: true, message: '主题不能为空' }],
                initialValue: emailInfo.subject
              },
            ]" placeholder="主题" />
          </a-form-item>
        </a-col>
        <a-col :sm="24" :lg="8">
          <a-form-item label="类型" :labelCol="customLabelCol" :wrapperCol="customWrapperCol">
            <a-select placeholder="请选择" @change="handleTypeChange" v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '未选择类型' }],
                initialValue: emailCheckedType
              }
            ]">
              <a-select-option v-for="(val, key) in emailType" :key="key" :value="key">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item v-if="plaintxtStatus">
        <a-textarea rows="20" placeholder="请输入邮件内容" v-decorator="[
          'message',
          {
            rules: [{ required: true, message: '邮件主体不能为空' }],
            initialValue: emailInfo.message
          }
        ]" />
      </a-form-item>
      <quill-editor v-if="!plaintxtStatus" :value="message" @change="quillEditorChange" />
      <a-divider>可替换参数显示</a-divider>
      <a-divider style="margin: 16px 0" orientation="left">类型变量</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item v-for="(item, keys) in business_params_now.vars" :key="keys" :term="item.title">{{ item.var }}
        </detail-list-item>
      </detail-list>
      <a-divider style="margin: 16px 0" orientation="left">公共变量</a-divider>
      <detail-list size="small" :col="4" class="detail-layout">
        <detail-list-item v-for="(item, keys) in common_params" :key="keys" :term="item.title">{{ item.var }}
        </detail-list-item>
      </detail-list>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { emailTemplateInfo, updateEmailTemplate, addEmailTemplate, getAddTempPage, EmailReplaceVars } from '@/api/email'
import { uploadFile, deleteFile } from '@/api/file'
import QuillEditor from '@/components/Editor/QuillEditor'
import DetailList from '@/components/tools/DetailList'

const DetailListItem = DetailList.Item

export default {
  name: 'EditEmailTemplate',
  components: {
    DetailList,
    DetailListItem,
    // quillEditor,
    QuillEditor
  },
  data() {
    return {
      description: '邮件模板编辑添加',
      value: 1,
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      customLabelCol: {
        lg: { span: 4 },
        sm: { span: 7 }
      },
      customWrapperCol: {
        lg: { span: 18 },
        sm: { span: 17 }
      },
      fontStyle: {
        fontWeight: 1000
      },
      queryData: {},
      checkPass: true,
      emailInfo: {},
      emailType: {},
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      templateStatus: true,
      plaintxtStatus: false,
      submitLoading: false,
      // 富文本编辑器
      editor: null,
      message: '',
      editorOption: {},
      // 显示普通编辑器还是富文本编辑器
      fileList: [],
      // 可替换变量
      business_params: {},
      common_params: {},
      business_params_now: {},
      emailCheckedType: ''
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    } else {
      this.loadType()
    }
    // 获取可替换变量
    this.loadReplaceVars()
  },
  mounted() {
  },
  watch: {
    emailCheckedType(val) {
      this.business_params_now = this.business_params[val]
    }
  },
  methods: {
    handleTypeChange(e) {
      this.business_params_now = this.business_params[e]
      console.log(this.business_params_now)
    },
    handlePlantextChange(e) {
      this.plaintxtStatus = e.target.checked
    },
    loadInfo(id) {
      new Promise((resolve, reject) => {
        emailTemplateInfo({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.emailInfo = res.data.info
        this.emailCheckedType = res.data.info.type
        this.plaintxtStatus = !!res.data.info.plantext
        this.message = res.data.info.message
        this.emailType = res.data.type
        this.fileList = res.data.info.file_list
        if (res.data.info.status === 1) {
          this.templateStatus = true
        }
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回管理员管理页面
        setTimeout(() => {
          this.$router.push({ name: 'EmailTemplateList' })
        }, 2000)
      })
    },
    loadType() {
      new Promise((resolve, reject) => {
        getAddTempPage().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.emailInfo = res.data.info
        this.emailType = res.data.type
      })
    },
    loadReplaceVars() {
      new Promise((resolve, reject) => {
        EmailReplaceVars().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.business_params = res.data.business_params
        this.common_params = res.data.common_params
      })
    },
    quillEditorChange(html) {
      this.message = html
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : 0
          // 文本内容
          if (this.plaintxtStatus) {
            values.message = this.form.getFieldValue('message')
          } else {
            values.message = this.message
          }
          values.plantext = values.plantext ? 1 : 0
          var attachments = []
          this.fileList.forEach((value, index) => {
            attachments.push(value.save_name)
          })
          values.attachments = attachments
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              updateEmailTemplate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              addEmailTemplate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    },
    changeFile(info) { // 上传文件中的三种状态调用
      let fileList = [...info.fileList]
      fileList = fileList.map(file => {
        if (file.response) {
          file.file_name = file.response.data.file_name
          file.save_name = file.response.data.save_name
          file.url = file.response.data.url
        }
        return file
      })
      this.fileList = fileList
      if (info.file.status !== 'uploading') {
        // 上传中
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败,` + info.file.error.message)
      }
    },
    customRequest(updateData) { // 上传提交
      const file = updateData.file
      const formData = new FormData()
      formData.append('file', file)
      updateData.onProgress()
      this.saveFile(formData, updateData)
    },

    saveFile(formData, updateData) {
      new Promise((resolve, reject) => {
        uploadFile(formData).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            resolve(response)
            // reject(response)
          }
        })
      }).then(res => {
        updateData.onSuccess(res)
      }).catch(error => {
        updateData.onError(error)
      })
    },
    removeFile(fileObj) {
      const path = fileObj.save_name
      if (fileObj.response === undefined) {
        return true
      }
      return new Promise((resolve, reject) => {
        deleteFile({ path: path }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
.edit_container {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.ql-editor {
  min-height: 400px;
}

.detail-layout {
  margin-left: 44px;
}

.mobile {
  .detail-layout {
    margin-left: unset;
  }

  .status-list {
    text-align: left;
  }
}
</style>
