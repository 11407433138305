var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"来源名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'from_name',
        {
          rules: [{ required: true, message: '邮件来源名称不能为空' }],
          initialValue: _vm.emailInfo.from_name
        },
      ]),expression:"[\n        'from_name',\n        {\n          rules: [{ required: true, message: '邮件来源名称不能为空' }],\n          initialValue: emailInfo.from_name\n        },\n      ]"}],attrs:{"placeholder":"显示的邮件来源名称"}})],1),_c('a-form-item',{attrs:{"label":"来源邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'from_email',
        {
          rules: [{ required: true, message: '邮件来源邮箱不能为空' }],
          initialValue: _vm.emailInfo.from_email
        },
      ]),expression:"[\n        'from_email',\n        {\n          rules: [{ required: true, message: '邮件来源邮箱不能为空' }],\n          initialValue: emailInfo.from_email\n        },\n      ]"}],attrs:{"placeholder":"显示的邮件来源邮箱"}})],1),_c('a-form-item',{attrs:{"label":"抄送","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'copyto',
        {
          initialValue: _vm.emailInfo.copyto
        },
      ]),expression:"[\n        'copyto',\n        {\n          initialValue: emailInfo.copyto\n        },\n      ]"}],attrs:{"placeholder":"抄送邮件到邮箱"}})],1),_c('a-form-item',{attrs:{"label":"密送","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'bind_copy_to',
        {
          initialValue: _vm.emailInfo.bind_copy_to
        },
      ]),expression:"[\n        'bind_copy_to',\n        {\n          initialValue: emailInfo.bind_copy_to\n        },\n      ]"}],attrs:{"placeholder":"密送邮件到邮箱"}})],1),_c('a-form-item',{attrs:{"label":"邮件附件","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-upload',{attrs:{"file-list":_vm.fileList,"multiple":false,"remove":_vm.removeFile,"customRequest":_vm.customRequest},on:{"change":_vm.changeFile}},[(_vm.fileList.length < 4)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to upload ")],1):_vm._e()],1)],1),_c('a-form-item',{attrs:{"label":"邮件是否为纯文本","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'plantext',
        {
          valuePropName: 'checked',
          initialValue: _vm.plaintxtStatus,
        },
      ]),expression:"[\n        'plantext',\n        {\n          valuePropName: 'checked',\n          initialValue: plaintxtStatus,\n        },\n      ]"}],on:{"change":_vm.handlePlantextChange}})],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          valuePropName: 'checked',
          initialValue: _vm.templateStatus,
        },
      ]),expression:"[\n        'status',\n        {\n          valuePropName: 'checked',\n          initialValue: templateStatus,\n        },\n      ]"}]},[_vm._v(" 是否启用 ")])],1),_c('a-divider',[_vm._v("主体")]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"sm":24,"lg":8}},[_c('a-form-item',{attrs:{"label":"名称","labelCol":_vm.customLabelCol,"wrapperCol":_vm.customWrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: '名称不能为空' }],
              initialValue: _vm.emailInfo.name
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: '名称不能为空' }],\n              initialValue: emailInfo.name\n            },\n          ]"}],attrs:{"placeholder":"名称"}})],1)],1),_c('a-col',{attrs:{"sm":24,"lg":8}},[_c('a-form-item',{attrs:{"label":"主题","labelCol":_vm.customLabelCol,"wrapperCol":_vm.customWrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'subject',
            {
              rules: [{ required: true, message: '主题不能为空' }],
              initialValue: _vm.emailInfo.subject
            },
          ]),expression:"[\n            'subject',\n            {\n              rules: [{ required: true, message: '主题不能为空' }],\n              initialValue: emailInfo.subject\n            },\n          ]"}],attrs:{"placeholder":"主题"}})],1)],1),_c('a-col',{attrs:{"sm":24,"lg":8}},[_c('a-form-item',{attrs:{"label":"类型","labelCol":_vm.customLabelCol,"wrapperCol":_vm.customWrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '未选择类型' }],
              initialValue: _vm.emailCheckedType
            }
          ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '未选择类型' }],\n              initialValue: emailCheckedType\n            }\n          ]"}],attrs:{"placeholder":"请选择"},on:{"change":_vm.handleTypeChange}},_vm._l((_vm.emailType),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1)],1)],1),(_vm.plaintxtStatus)?_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'message',
        {
          rules: [{ required: true, message: '邮件主体不能为空' }],
          initialValue: _vm.emailInfo.message
        }
      ]),expression:"[\n        'message',\n        {\n          rules: [{ required: true, message: '邮件主体不能为空' }],\n          initialValue: emailInfo.message\n        }\n      ]"}],attrs:{"rows":"20","placeholder":"请输入邮件内容"}})],1):_vm._e(),(!_vm.plaintxtStatus)?_c('quill-editor',{attrs:{"value":_vm.message},on:{"change":_vm.quillEditorChange}}):_vm._e(),_c('a-divider',[_vm._v("可替换参数显示")]),_c('a-divider',{staticStyle:{"margin":"16px 0"},attrs:{"orientation":"left"}},[_vm._v("类型变量")]),_c('detail-list',{staticClass:"detail-layout",attrs:{"size":"small","col":4}},_vm._l((_vm.business_params_now.vars),function(item,keys){return _c('detail-list-item',{key:keys,attrs:{"term":item.title}},[_vm._v(_vm._s(item.var)+" ")])}),1),_c('a-divider',{staticStyle:{"margin":"16px 0"},attrs:{"orientation":"left"}},[_vm._v("公共变量")]),_c('detail-list',{staticClass:"detail-layout",attrs:{"size":"small","col":4}},_vm._l((_vm.common_params),function(item,keys){return _c('detail-list-item',{key:keys,attrs:{"term":item.title}},[_vm._v(_vm._s(item.var)+" ")])}),1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }